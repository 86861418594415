<template>
  <div class="d-flex justify-content-center countdown-button">
    <b-button
      :class="buttonClass"
      :variant="variant"
      @click="$emit('click', $event)"
    >
      {{ title }} ({{ countDown }})</b-button
    >
  </div>
</template>

<script>
import { BButton } from "bootstrap-vue";

export default {
  components: { BButton },
  name: "CountDownButton",
  props: {
    title: String,
    buttonClass: {
      type: String,
      default: "w-50",
      required: false,
    },
    variant: {
      type: String,
      default: "success",
      required: false,
    },
    countFrom: {
      type: Number,
      required: false,
      default: 10,
    },
  },
  data() {
    return {
      countDown: this.countFrom > 0 ? this.countFrom : 10,
    };
  },
  mounted() {
    const interval = setInterval(() => {
      this.countDown--;
      if (this.countDown <= 0) {
        clearInterval(interval);
        this.$emit("zero");
      }
    }, 1000);
  },
};
</script>

<style scoped>
@media (max-width: 500px) {
  .countdown-button {
    width: 100%;
  }
}
</style>
